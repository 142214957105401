






































































































import { MajorType } from '@/interface/costQuotaLibrary';
import {
  moveMajor,
  movePartMajor,
  queryEnterpriseMajor,
} from '@/api/costQuotaLibrary/projectMajor';
import { FormModel } from 'ant-design-vue';
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
interface moveClassifyType {
  bdCode: string;
  bdName: string;
  unit: string;
  majorNbr: string;
}
interface formType {
  parentSourceMajorNbr: string;
  parentTargetMajorNbr: string;
  sourceMajorNbr?: string;
  targetMajorNbr?: string;
}
export default Vue.extend({
  name: 'moveClassify',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    needMoveClassify: {
      type: Array as PropType<moveClassifyType[] | string[]>,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(['userInfo', 'currentMajorInfo']),
    moveLabel(): string {
      return this.needMoveClassify.length
        ? '将已选择清单项移动到以下分类：'
        : '移入到';
    },
    dialogTitle(): string {
      return this.needMoveClassify.length ? '清单项移动' : '工程分类移动';
    },
    labelCol(): { span: number } {
      return { span: this.needMoveClassify.length ? 24 : 4 };
    },
    wrapperCol(): { span: number } {
      return { span: this.needMoveClassify.length ? 24 : 18 };
    },
  },
  data() {
    return {
      majorList: [] as MajorType[],
      subMajorList: [] as MajorType[],
      moveSubMajorList: [] as MajorType[],
      form: {
        parentSourceMajorNbr: '',
        parentTargetMajorNbr: '',
      } as formType,
      rules: {
        sourceMajorNbr: [
          {
            required: true,
            message: '请选择分类',
            trigger: 'input',
          },
        ],
        targetMajorNbr: [
          { required: true, message: '请选择分类', trigger: 'input' },
        ],
      },
    };
  },
  watch: {
    visible: function (val) {
      if (val) {
        this.form = {
          parentSourceMajorNbr: '',
          parentTargetMajorNbr: '',
        };
        this.getMajor();
      }
    },
  },
  methods: {
    sourceMajorValidate(prop) {
      (this.$refs.ruleForm as FormModel).validateField([prop], (err) => {
        console.log(err);
      });
    },
    targetDisabled(sequenceNbr) {
      let sourceMajorNbr = this.form.sourceMajorNbr;
      if (this.needMoveClassify?.length) {
        sourceMajorNbr = this.currentMajorInfo?.sequenceNbr;
      }
      return sourceMajorNbr === sequenceNbr;
    },
    moveSelectChange(value) {
      this.moveSubMajorList = this.filterMajor(value);
      this.$set(this.form, 'targetMajorNbr', null);
      delete this.form.targetMajorNbr;
    },
    selectChange(value) {
      this.subMajorList = this.filterMajor(value);
      this.$set(this.form, 'sourceMajorNbr', null);
      delete this.form.sourceMajorNbr;
    },
    filterMajor(value) {
      const list = this.majorList.filter((item) => {
        return item.sequenceNbr === value;
      });
      if (!list.length) return [];
      return list[0]?.majorList;
    },
    getMajor() {
      const { agencyCode } = this.userInfo;
      queryEnterpriseMajor(agencyCode).then(({ status, result }) => {
        if (status !== 200) return;
        this.majorList = result.majorList;
        const defaultVal = result.majorList[0]?.sequenceNbr;
        this.$set(this.form, 'parentSourceMajorNbr', defaultVal);
        this.$set(this.form, 'parentTargetMajorNbr', defaultVal);
        this.moveSelectChange(defaultVal);
        this.selectChange(defaultVal);
      });
    },
    cancel() {
      (this.$refs.ruleForm as FormModel).resetFields();
      this.$emit('update:visible', false);
    },
    save() {
      (this.$refs.ruleForm as FormModel).validate((valid) => {
        if (!valid) return;
        if (!this.needMoveClassify.length) {
          this.moveMajorHandler();
        } else {
          this.movePartMajorHandler();
        }
      });
    },
    moveMajorHandler() {
      const { sourceMajorNbr, targetMajorNbr } = this.form;
      if (!sourceMajorNbr || !targetMajorNbr) return;
      moveMajor({ sourceMajorNbr, targetMajorNbr }).then((res) => {
        this.moveHandler(res);
      });
    },
    /**
     * 获取需要移动的清单列表
     */
    getQyStlBillDeList() {
      const needMoveClassify = this.needMoveClassify as moveClassifyType[];
      return needMoveClassify.map((item) => {
        const { bdCode, bdName, unit, majorNbr } = item;
        return { bdCode, bdName, unit, majorNbr };
      });
    },
    movePartMajorHandler() {
      const { targetMajorNbr } = this.form;
      const needMoveClassify = this.needMoveClassify[0];
      if (typeof needMoveClassify === 'string') {
        this.form.sourceMajorNbr = needMoveClassify;
        this.moveMajorHandler();
        return;
      }
      let params = {
        targetMajorNbr,
        qyStlBillDeList: this.getQyStlBillDeList(),
      };
      movePartMajor(params).then((res) => {
        this.moveHandler(res);
      });
    },
    moveHandler({ status, result }) {
      if (status === 200 && result) {
        this.$emit('callback');
        this.cancel();
        return this.$message.success('移动成功');
      }
      this.$message.error('移动失败');
    },
  },
});
